@import 'styles/variables.scss';

.container {
  @include container;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.heading {
  @include heading-l;

  color: $greyscale-900;
  margin: $spacing-xxl 0 $spacing-l;
}

.description {
  @include body-m;

  color: $greyscale-700;
  max-width: 700px;
}

.button {
  width: 200px;
  margin-top: $spacing-l;
}
